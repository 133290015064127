<script>
export default {
  name: 'nav',
  computed: { 
    SidebarAdminItems () {
      return [{
        _name: 'CSidebarNav',
        _attrs: { style: 'color: #000;' },
        _children: [
          {
            _name: 'CSidebarNavTitle',
            _children: ['Administador'],
            _attrs: {style:"background-color: #556976 !important;"}
          },
          {
            _name: 'CSidebarNavItem',
            name: 'Cuentas',
            to: '/accounts',
            icon: 'cil-group',
          }
        ]
      }]
    }
  }
}
</script>
