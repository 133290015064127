<template>
  <div class="c-app" style="background-color: #fff" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <TheAdminSidebar/>
    <TheAside/>
    <CWrapper>
      <TheAdminHeader/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter/>
    </CWrapper>
    <loading :active="visible" :can-cancel="true"></loading>
    <CToaster placement="top-end">
      <template v-for="(toast, key) in toasts">
        <CToast :color="toast.color" v-bind="toast" :key="'toast' + key" :show="true">
          {{toast.content}}
        </CToast>
      </template>
    </CToaster>
  </div>
</template>
<script>
import TheAdminSidebar from './TheAdminSidebar'
import TheAdminHeader from './TheAdminHeader'
import TheFooter from './TheFooter'
import TheAside from './TheAside'
import store from '../store'
import Vue from 'vue';
import Loading from 'vue-loading-overlay/dist/vue-loading.js';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'TheAdminContainer',
  components: {
    TheAdminSidebar,
    TheAdminHeader,
    TheFooter,
    TheAside,
    Loading
  },
  data() {
      return {
        
      }
  },
  mounted: async function() {
    
  }, 
  computed: {   
    toasts () {
      return store.state.toasts;
    },
    visible() {
      return store.state.isLoading;
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
